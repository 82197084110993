<template>
    <v-card>
        
    </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>